import {
  TextField,
  Box,
  Button,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React from "react";
import { MenuItem, Typography } from "@material-ui/core";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const marketplacesMap = {
  "amazon.ae": "🇦🇪",
  "amazon.ca": "🇨🇦",
  "amazon.cn": "🇨🇳",
  "amazon.co.jp": "🇯🇵",
  "amazon.co.uk": "🇬🇧",
  "amazon.com": "🇺🇸",
  "amazon.com.au": "🇦🇺",
  "amazon.com.be": "🇧🇪",
  "amazon.com.br": "🇧🇷",
  "amazon.com.mx": "🇲🇽",
  "amazon.com.tr": "🇹🇷",
  "amazon.de": "🇩🇪",
  "amazon.eg": "🇪🇬",
  "amazon.es": "🇪🇸",
  "amazon.fr": "🇫🇷",
  "amazon.in": "🇮🇳",
  "amazon.it": "🇮🇹",
  "amazon.nl": "🇳🇱",
  "amazon.pl": "🇵🇱",
  "amazon.sa": "🇸🇦",
  "amazon.se": "🇸🇪",
  "amazon.sg": "🇸🇬",
};

export default function ReviewsForm() {
  const [asin, setAsin] = React.useState("");
  const [marketplace, setMarketplace] = React.useState("amazon.com");
  const [isContactFormOpen, setIsContactFormOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const emailRef = React.useRef();

  const submitEmail = (event) => {
    event.preventDefault();
    if (emailRegex.test(emailRef.current.value)) {
      setIsSubmitting(true);
      fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/8861949/4704d53d-284a-41f3-a731-fb50a46a9574",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: emailRef.current.value,
              },
            ],
          }),
        }
      )
        .then(() => {
          window.parent &&
            window.parent.postMessage({ type: "hubspotForm" }, "*");
        })
        .then(() => {
          window.location.href = `/reviews/${asin}?marketplace=${marketplace}`;
        });
    } else {
      setEmailError(true);
      console.log("invalid email");
    }
  };

  const revalidateEmail = () => {
    if (emailError && emailRegex.test(emailRef.current.value)) {
      setEmailError(false);
    }
  };

  React.useEffect(() => {
    const height =
      document.body.scrollHeight > 300 ? document.body.scrollHeight : 300;
    window.parent &&
      window.parent.postMessage({ type: "resize", height: height }, "*");
  });

  return (
    <Box maxWidth={600} mx="auto">
      <Typography variant="h5" align="center" style={{ marginBottom: 24 }}>
        Analyze your Amazon reviews
      </Typography>
      <Typography variant="body1" align="center">
        Use this free tool to highlight potential quality issues in your
        product.
      </Typography>

      <Box display="flex" justifyContent="center">
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            marginTop: 8,
            maxWidth: 500,
            alignSelf: "center",
          }}
        >
          <Select
            value={marketplace}
            onChange={(e) => setMarketplace(e.target.value)}
            renderValue={(value) => marketplacesMap[value]}
            sx={{
              height: "40px",
              width: "70px",
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {Object.keys(marketplacesMap).map((marketplace) => (
              <MenuItem value={marketplace} key={marketplace}>
                {marketplacesMap[marketplace]} {marketplace}
              </MenuItem>
            ))}
          </Select>
          <InputBase
            placeholder="Product ASIN"
            value={asin}
            onChange={(e) => setAsin(e.target.value)}
            sx={{ ml: 1, flex: 1 }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <Button
            variant="text"
            color="primary"
            onClick={() => setIsContactFormOpen(true)}
            sx={{ mx: 1 }}
          >
            Analyze reviews
          </Button>
        </Paper>
      </Box>

      <Dialog
        open={isContactFormOpen}
        onClose={() => setIsContactFormOpen(false)}
      >
        <Box component="form" id="review-analysis-form" onSubmit={submitEmail}>
          <DialogTitle>Access your review analysis</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Provide your email to gain access to your review analysis.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              inputRef={emailRef}
              error={emailError}
              helperText={emailError ? "Invalid email" : ""}
              onChange={revalidateEmail}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsContactFormOpen(false)}>Cancel</Button>
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
