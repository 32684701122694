import {
  Grid,
  Box,
  LinearProgress,
  Link,
  Typography,
  Button,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import Rating from "@mui/material/Rating";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function Reviews({ asin, marketplace }) {
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [reviewsSummary, setReviewsSummary] = React.useState({
    product: {},
    summary: {},
    reviews: [],
    gptSentences: [],
    gptSummary: "",
  });

  const fetchReviews = async (asin) => {
    for (let page = 1; page <= 5; page++) {
      setLoadingMessage(`Retrieving recent reviews (page ${page} of 5)...`);
      const response = await fetch(
        `/api/products/${asin}/reviews/ingest?marketplace=${marketplace}&page=${page}`
      );
      const body = await response.json();
      if (!body.request_info.success) {
        throw new Error(body.request_info.message);
      }
    }

    setLoadingMessage("Analyzing reviews...");
    await fetch(
      `/api/products/${asin}/reviews/sentences?marketplace=${marketplace}`
    );

    setLoadingMessage("Generating summary...");
    await fetch(
      `/api/products/${asin}/reviews/summary?marketplace=${marketplace}`
    );

    setLoadingMessage("Preparing report...");
    fetch(`/api/products/${asin}/reviews?marketplace=${marketplace}`)
      .then((response) => response.json())
      .then((data) => {
        setReviewsSummary(data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((error) => {
        console.log(error.message);
        setLoadingMessage(
          "We encountered an error processing your report. Please make sure you entered the correct ASIN and marketplace and try again later."
        );
        setHasError(true);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchReviews(asin).catch((error) => {
      console.log(error.message);
      setLoadingMessage(
        "The requested page could not be found on Amazon. Make sure you entered the correct ASIN and marketplace (for example, an ASIN from amazon.com might not be valid on amazon.fr)."
      );
      setHasError(true);
      setLoading(false);
    });
  }, [asin]);

  React.useEffect(() => {
    const height =
      document.body.scrollHeight > 300 ? document.body.scrollHeight : 300;
    window.parent &&
      window.parent.postMessage({ type: "resize", height: height }, "*");
  });

  const reviewHighlights = React.useMemo(
    () =>
      reviewsSummary.gptSentences
        .map(({ id, sentence }) => {
          const review = reviewsSummary.reviews.find((review) => {
            return review.id === id;
          });
          return { ...review, gptExtract: sentence };
        })
        .filter((review) => review.rating <= 4),
    [reviewsSummary]
  );

  if (loading) {
    return (
      <Box maxWidth={600} mx="auto" paddingTop={10}>
        <Typography variant="h5" align="center" style={{ marginBottom: 24 }}>
          {loadingMessage}
        </Typography>
        <LinearProgress />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box maxWidth={600} mx="auto" paddingTop={10}>
        <Typography variant="h5" align="center" style={{ marginBottom: 24 }}>
          There was an error analyzing your reviews
        </Typography>
        <Typography variant="body2">{loadingMessage}</Typography>
      </Box>
    );
  }

  const { product, summary, reviews, gptSentences, gptSummary } =
    reviewsSummary;

  const ratings = {
    five: summary.rating_breakdown.five_star.percentage,
    four: summary.rating_breakdown.four_star.percentage,
    three: summary.rating_breakdown.three_star.percentage,
    two: summary.rating_breakdown.two_star.percentage,
    one: summary.rating_breakdown.one_star.percentage,
  };

  return (
    <Box maxWidth={1000} mx="auto">
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Link href={product.link} target="_blank">
              <img src={product.image} alt={product.title} />
            </Link>
          </Grid>
          <Grid item xs>
            <Typography variant="h5">
              <Link href={product.link} target="_blank">
                {product.title}
              </Link>
            </Typography>
            <HtmlTooltip
              title={<RatingsTooltip ratings={ratings} />}
              placement="bottom-start"
            >
              <Stack direction="row" spacing={1}>
                <Rating value={summary.rating} precision={0.5} readOnly />
                <Typography style={{ color: "#666666" }}>
                  {summary.rating} out of 5 stars (
                  {summary.ratings_total.toLocaleString()} ratings)
                </Typography>
              </Stack>
            </HtmlTooltip>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Summary</Typography>
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {gptSummary}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Review Highlights</Typography>
          {reviewHighlights.map((review, index) => (
            <Box key={index} mb={2}>
              <Stack direction="row" spacing={1}>
                <Rating
                  value={review.rating}
                  precision={0.5}
                  readOnly
                  size="small"
                />
                <Typography variant="body2" style={{ color: "#666666" }}>
                  {review.rating} out of 5 stars ({review.date.raw})
                </Typography>
              </Stack>
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                {review.gptExtract}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

function RatingsTooltip({ ratings }) {
  return (
    <React.Fragment>
      <Typography>5 stars ({ratings.five}%)</Typography>
      <LinearProgress
        variant="determinate"
        value={ratings.five}
        style={{ marginBottom: 8 }}
      />
      <Typography>4 stars ({ratings.four}%)</Typography>
      <LinearProgress
        variant="determinate"
        value={ratings.four}
        style={{ marginBottom: 8 }}
      />
      <Typography>3 stars ({ratings.three}%)</Typography>
      <LinearProgress
        variant="determinate"
        value={ratings.three}
        style={{ marginBottom: 8 }}
      />
      <Typography>2 stars ({ratings.two}%)</Typography>
      <LinearProgress
        variant="determinate"
        value={ratings.two}
        style={{ marginBottom: 8 }}
      />
      <Typography>1 stars ({ratings.one}%)</Typography>
      <LinearProgress
        variant="determinate"
        value={ratings.one}
        style={{ marginBottom: 8 }}
      />
    </React.Fragment>
  );
}
